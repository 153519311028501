<template>
  <div class="partner ma-2">
    <v-card width="400" height="260" class="uk-inline uk-border-radius" elevation="12">

      <v-img
        :src="getBased(category.category_thumbnail)"
        height="200px"
        :alt="category.category_thumbnail"
      > </v-img>

      <v-card-title class="justify-center">
        {{ category.category_title }}
      </v-card-title>
      
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'Category',

  props: [
    'category'
  ],
}
</script>