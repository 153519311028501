<template>
  <section class="ECourses-section uk-text-center">
    <div class="uk-container">
      <div class="uk-text-center uk-margin-large-bottom">
        <h1 class="text-center main-color">{{ $t("coursesCategories") }}</h1>
      </div>

      <div
        class="uk-position-relative uk-visible-toggle uk-light"
        tabindex="-1"
        uk-slider="center: false"
      >
        <ul
          class="uk-slider-items uk-child-width-1-5@l uk-child-width-1-2@s uk-child-width-1-3@m uk-grid"
        >
          <li
            v-for="category in this.getCourseCategories"
            :key="category.category_id"
          >
            <router-link
              :to="{ path: '/courses/list/' + category.category_id }"
              class="link"
            >
              <Category :category="category" />
            </router-link>
          </li>
        </ul>
        <a
          class="uk-position-center-left uk-position-large"
          href="#"
          uk-slidenav-previous
          uk-slider-item="previous"
        ></a>
        <a
          class="uk-position-center-right uk-position-large"
          href="#"
          uk-slidenav-next
          uk-slider-item="next"
        ></a>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Category from "./Category";

export default {
  name: "CourseCategories",
  components: {
    Category
  },
  created() {
    this.fetchCourseCategories();
  },
  methods: {
    ...mapActions("Home", ["fetchCourseCategories"])
  },

  computed: mapGetters("Home", ["getCourseCategories"])
};
</script>

<style lang="scss" scoped>
.link {
  text-decoration: none !important;
}
.uk-slider-items {
  display: flex;
  justify-content: center;
}
</style>
